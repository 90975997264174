import React from 'react';
import PropTypes from "prop-types";
import Img from 'gatsby-image'
import TextTruncate from 'react-text-truncate';
import { Link } from 'gatsby'

import './index.scss';

const Post = ({post, imageType, category}) => (
    <div className="post">
        <Link to={`/${post.category.slug ? post.category.slug : category}/${post.slug}/`}>
            <Img
                className={`post__image${imageType === 'rounded' ? ' post__image--rounded' : ''}`}
                fluid={post.image.childImageSharp.fluid}
                alt={post.title}
            />
        </Link>
        <time className="post__date">{post.created_at}</time>
        <Link to={`/${post.category.slug ? post.category.slug : category}/${post.slug}/`}>
            <h3 className="post__title">{post.title}</h3>
            <div className="post__content">
                <TextTruncate
                    line={3}
                    truncateText="..."
                    text={post.content}
                />
            </div>
        </Link>
    </div>
);

Post.propTypes = {
    post: PropTypes.object,
    imageType: PropTypes.string,
}

export default Post;