import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Post from '@components/Post'
import SEO from "../components/seo"

import './category.scss'

const CategoryTemplate = ({ data }) => {
    const posts = data.strapiCategory.posts;
    posts.sort((a, b) => {
        if (a.id > b.id) {
            return -1
        }
        if (b.id > a.id) {
            return 1
        }

        return 0
    })
    return (
    <Layout>
        <SEO
            title={`${data.strapiCategory.name} - opis górkich szlaków`}
            description={data.strapiCategory.description}
        />
        <div className="category-view">
            <h1 className="category-view__title">{data.strapiCategory.name}</h1>
            <p className="category-view__description">{data.strapiCategory.description}</p>
            <div className="category-view__list">
                {posts.map(post => (
                    <Post key={post.id} post={post} category={data.strapiCategory.slug} />
                ))}
            </div>
        </div>
    </Layout>
    )
}

export default CategoryTemplate

export const query = graphql`
    query CategoryTemplate($slug: String!) {
        strapiCategory(slug: {eq: $slug}) {
            name
            slug
            description
            posts {
                slug
                id
                category
                created_at(formatString: "DD MMMM YYYY", locale: "pl")
                title
                content
                image {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            image {
                childImageSharp {
                    fixed(width: 200, height: 125) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    }
`